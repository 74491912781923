import { VuexModule, Mutation, Action, Module } from "vuex-module-decorators";
import Store from "@/store/index";
import Api from "@/api";
import { AuthorisationModel, TokenModel } from "@/api/generated";

@Module({ dynamic: true, store: Store, name: "user", namespaced: true })
export default class UserModule extends VuexModule {
  private user: TokenModel = {
    userId: "",
    role: "",
    accessTokenExpires: "",
    deleted: false,
    enabled: true,
    loginAttempts: 0,
    modifiedUtc: "",
    roleDescription: "",
    emailAddress: "",
    firstname: "",
    lastname: "",
    fullname: "",
    createdUtc: "",
    accessToken: null,
    refreshToken: null,
    agencyId: ""
  };

  @Mutation
  public async setUserData(data: TokenModel) {
    this.user = data;
    localStorage.setItem("user", JSON.stringify(this.user));
  }

  @Mutation
  public clearUserData(): void {
    localStorage.removeItem("user");
    location.reload();
  }

  @Action({ rawError: true, commit: "setUserData" })
  public async login(credentials: AuthorisationModel): Promise<TokenModel> {
    const response = await Api.AuthService.apiAuthTokenPost(credentials);
    return response.data;
  }

  @Action({ commit: "clearUserData" })
  public logout(): void {
    return;
  }

  @Action
  public async refreshToken(refreshToken: TokenModel): Promise<TokenModel> {
    try {
      const response = await Api.AuthService.apiAuthRefreshTokenPost(
        refreshToken
      );
      this.context.commit("setUserData", response.data);
      return response.data;
    } catch {
      this.context.commit("clearUserData");
      return {
        userId: "",
        emailAddress: "",
        firstname: "",
        lastname: "",
        fullname: "",
        createdUtc: "",
        accessToken: null,
        accessTokenExpires: "",
        refreshToken: null,
        role: "",
        deleted: false,
        enabled: true,
        loginAttempts: 0,
        modifiedUtc: "",
        roleDescription: "",
        agencyId: ""
      };
    }
  }

  get loggedIn(): boolean {
    return !!this.user.accessToken;
  }

  get userName(): string {
    return this.user.fullname ?? "";
  }

  get userId(): string {
    return this.user.userId ?? "";
  }
  get role(): string {
    return this.user.role ?? "";
  }
  get agencyId(): string {
    return this.user.agencyId ?? "";
  }
}
