import { RouteConfig } from "vue-router";
import crewMemberEditRoutes from "./crewMemberEdit";
import crewMemberOverviewRoutes from "./crewMemberOverview";
import crewMemberAgencyAdminEditRoutes from "./crewMemberAgencyAdminEdit";
import landWorkersOverview from "./landWorkersOverview";
import landWorkersEditRoutes from "./landWorkersEdit";

const landWorkerRoutes: Array<RouteConfig> = [
  {
    path: "overview",
    name: "Land Workers Overview",
    component: () => import("@/views/land-worker/LandWorkerOverview.vue"),
    meta: {
      title: "Land Workers",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"]
    },
    redirect: { name: "Active Land Workers" },
    children: landWorkersOverview
  },
  {
    path: "create",
    name: "Land Worker Create",
    component: () => import("@/views/land-worker/LandWorkerCreate.vue"),
    meta: {
      title: "Land Workers Create",
      noAuth: false,
      requiresAuth: ["USR", "ADM"]
    }
  },
  {
    path: "edit/:crewMemberId",
    name: "Land Worker Edit",
    component: () => import("@/views/land-worker/LandWorkerEditIndex.vue"),
    redirect: { name: "Land Worker Details Edit" },
    props: true,
    meta: {
      title: "Land Workers Edit",
      noAuth: false,
      requiresAuth: ["USR", "ADM"],
      icon: "mdi-account-group"
    },
    children: landWorkersEditRoutes
  },
  {
    path: "edit/:crewMemberId",
    name: "Land Worker Edit Agency",
    component: () =>
      import("@/views/crew-member/CrewMemberAvailableAgencyAdminEditIndex.vue"),
    redirect: { name: "Crew Member Certificate Agency Admin Edit" },
    props: true,
    meta: {
      title: "Land Workers Edit",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"],
      icon: "mdi-account-group"
    },
    children: crewMemberAgencyAdminEditRoutes
  }
];

export default landWorkerRoutes;
