
















import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import { CountryModel } from "@/api/generated";
import Api from "@/api";

const snackbarModule = getModule(SnackbarModule);

@Component
export default class NationalityAutocomplete extends Vue {
  private loading = false;
  private countries: Array<CountryModel> = [];

  private async created() {
    try {
      this.loading = true;
      const response = await Api.CountryService.apiCountryGet();
      this.countries = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to load nationalities");
    } finally {
      this.loading = false;
    }
  }
}
