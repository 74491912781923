import { RouteConfig } from "vue-router";

const companyEnquiryOverviewRoutes: Array<RouteConfig> = [
  {
    path: "initial",
    name: "Initial Company Enquiry",
    component: () =>
      import(
        "@/views/company/enquiry/overview/CompanyEnquiryInitialOverview.vue"
      ),
    meta: {
      title: "Company Enquiries",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"]
    }
  },
  {
    path: "open",
    name: "Company Enquiry Open",
    component: () =>
      import("@/views/company/enquiry/overview/CompanyEnquiryOpenOverview.vue"),
    meta: {
      title: "Active Contracts",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"]
    }
  },
  {
    path: "expiring",
    name: "Company Expiring Contracts",
    component: () =>
      import(
        "@/views/company/enquiry/overview/CompanyEnquiryExpiringOverview.vue"
      ),
    meta: {
      title: "Expiring Contracts",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"]
    }
  },
  {
    path: "expired",
    name: "Company Expired Contracts",
    component: () =>
      import(
        "@/views/company/enquiry/overview//CompanyEnquiryExpiredOverview.vue"
      ),
    meta: {
      title: "Expired Contracts",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"]
    }
  },
  {
    path: "closed",
    name: "Company Enquiry Closed",
    component: () =>
      import("@/views/company/enquiry/overview/CompanyEnquiryClosedOverview.vue"),
    meta: {
      title: "Closed Contracts",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"]
    }
  }
];

export default companyEnquiryOverviewRoutes;
