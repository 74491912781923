import { RouteConfig } from "vue-router";

export const companyEditRoutes: Array<RouteConfig> = [
  {
    path: "details",
    name: "Company Details Edit",
    component: () => import("@/views/company/CompanyEdit.vue"),
    props: true,
    meta: {
      title: "Details",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"]
    }
  },
  {
    path: "documents",
    name: "Company Documents",
    component: () => import("@/views/company/CompaniesDocuments.vue"),
    props: true,
    meta: {
      title: "Documents",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"],
      icon: "mdi-document"
    }
  },
  {
    path: "companyNotes",
    name: "Company Notes",
    component: () => import("@/views/company/CompanyNotes.vue"),
    meta: { title: "Notes", noAuth: false, requiresAuth: ["USR", "ADM"] }
  }
];