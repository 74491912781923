import { RouteConfig } from "vue-router";

const dashboardRoutes: Array<RouteConfig> = [
  {
    path: "analytics",
    name: "Analytics",
    component: () => import("@/views/dashboard/Analytics.vue"),
    props: true,
    meta: {
      title: "Analytics",
      noAuth: false,
      requiresAuth: ["USR", "ADM"]
    }
  },
  {
    path: "skilledWorkerAnalytics",
    name: "Skilled Worker Analytics",
    component: () => import("@/views/dashboard/SkilledWorkerAnalytics.vue"),
    props: true,
    meta: {
      title: "Skilled Worker Analytics",
      noAuth: false,
      requiresAuth: ["USR", "ADM"]
    }
  },
  {
    path: "vessel-enquiry/active",
    name: "Vessel Enquiry Active",
    component: () => import("@/views/dashboard/ActiveEnquiriesOverview.vue"),
    props: true,
    meta: {
      title: "Active Contracts",
      noAuth: false,
      requiresAuth: ["USR", "ADM"]
    }
  },
  // {
  //   path: "vessel-enquiry/expired",
  //   name: "Vessel Enquiry Expired",
  //   component: () => import("@/views/dashboard/ExpiredEnquiriesOverview.vue"),
  //   props: true,
  //   meta: {
  //     title: "Expired Enquiries",
  //     noAuth: false
  //   }
  // },
  {
    path: "certificates/expiring",
    name: "Crew Member Certificates Expiring",
    component: () =>
      import("@/views/dashboard/ExpiringCertificatesOverview.vue"),
    props: true,
    meta: {
      title: "Expiring Certificates",
      noAuth: false,
      requiresAuth: ["USR", "ADM"]
    }
  },
  {
    path: "passports/expiring",
    name: "Crew Member Passports Expiring",
    component: () => import("@/views/dashboard/ExpiringPassportOverview.vue"),
    props: true,
    meta: {
      title: "Expiring Passports",
      noAuth: false,
      requiresAuth: ["USR", "ADM"]
    }
  },
  {
    path: "vesselDocuments/expiring",
    name: "Vessel Documents Expiring",
    component: () =>
      import("@/views/dashboard/ExpiringVesselDocumentsOverview.vue"),
    props: true,
    meta: {
      title: "Expiring Vessel Documents",
      noAuth: false,
      requiresAuth: ["USR", "ADM"]
    }
  }
];

export default dashboardRoutes;
