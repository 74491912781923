import { RouteConfig } from "vue-router";
import { vesselEditRoutes } from "./vesselEdit";
import companiesOverviewRoutes from "./companiesOverview";
import { companyEditRoutes } from "./companyEdit";

export const companiesRoutes: Array<RouteConfig> = [
  {
    path: "overview",
    name: "Companies Overview",
    component: () => import("@/views/company/CompaniesOverview.vue"),
    meta: {
      title: "Companies",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"]
    },
    redirect: { name: "Companies" },
    children: companiesOverviewRoutes
  },
  {
    path: "edit/:vesselId",
    name: "Company Edit",
    component: () => import("@/views/company/EditIndex.vue"),
    redirect: { name: "Company Details Edit" },
    props: true,
    meta: {
      title: "Company Edit",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"],
      icon: "mdi-account-group"
    },
    children: companyEditRoutes
  }
];
