import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import UserModule from "./store/userModule";
import { getModule } from "vuex-module-decorators";
import globals from "@/components/common/_globals";
import vueFilters from "./utility/vueFilters";
import VueApexCharts from "vue-apexcharts";

Vue.config.productionTip = false;
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

globals.registerBaseComponents();
vueFilters.register();

new Vue({
  router,
  store,
  created() {
    const userString: string | null = localStorage.getItem("user");
    if (userString) {
      const userData = JSON.parse(userString);
      const userModule = getModule(UserModule);
      userModule.setUserData(userData);
    }
  },
  vuetify,
  render: h => h(App)
}).$mount("#app");
