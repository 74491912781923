import { RouteConfig } from "vue-router";

const companyEnquiryEditRoutes: Array<RouteConfig> = [
  {
    path: "details",
    name: "Company Enquiry Details Edit",
    component: () => import("@/views/company/enquiry/CompanyEnquiryEdit.vue"),
    props: true,
    meta: {
      title: "Details",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"]
    }
  },
  {
    path: "documents",
    name: "Company Enquiry Documents Edit",
    component: () =>
      import("@/views/company/enquiry/CompanyEnquiryDocuments.vue"),
    props: true,
    meta: {
      title: "Documents",
      noAuth: false,
      requiresAuth: ["USR", "ADM"],
      icon: "mdi-document"
    }
  },
  {
    path: "invoice",
    name: "Company Enquiry Invoice Edit",
    component: () => import("@/views/company/enquiry/CompanyEnquiryInvoice.vue"),
    props: true,
    meta: {
      title: "Invoicing",
      noAuth: false,
      requiresAuth: ["USR", "ADM"],
      icon: "mdi-document"
    }
  }
];

export default companyEnquiryEditRoutes;
