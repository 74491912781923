

























































import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import excelExportService from "@/services/excelExportService";
import fileDownload from "js-file-download";
import SnackbarModule from "@/store/snackbarModule";
import UserModule from "@/store/userModule";

const userModule = getModule(UserModule);
const snackbarModule = getModule(SnackbarModule);

@Component
export default class BaseDataTable extends Vue {
  @Prop(Boolean) isCompaniesTable?: boolean;
  @Prop({ type: String, default: "" }) private title!: string;
  @Prop({ type: String, default: "Create" }) private btnText!: string;
  @Prop({ type: Boolean, default: false }) private noSearch!: boolean;
  @Prop({ type: Boolean, default: false }) private noCreate!: boolean;
  @Prop({ type: Boolean, default: false }) private noCard!: boolean;

  private search = "";

  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }

  private async exportAllVessels() {
    try {
      const file = await excelExportService.exportAllVessels(this.search);
      fileDownload(
        new Blob([file.data], {
          type: file.extension ? file.extension : "undefined"
        }),
        `All Vessels-export.xlsx`
      );
    } catch {
      snackbarModule.setSnackbarMessage("Failed to Export");
    }
  }

  private async exportAllVesselsWithContracts() {
    try {
      const file = await excelExportService.exportAllVesselsWithContracts(
        this.search
      );
      fileDownload(
        new Blob([file.data], {
          type: file.extension ? file.extension : "undefined"
        }),
        `Vessels With Contracts-export.xlsx`
      );
    } catch {
      snackbarModule.setSnackbarMessage("Failed to Export");
    }
  }

  private async exportAllVesselsWithoutContracts() {
    try {
      const file = await excelExportService.exportAllVesselsWithoutContracts(
        this.search
      );
      fileDownload(
        new Blob([file.data], {
          type: file.extension ? file.extension : "undefined"
        }),
        `Vessels Without Contracts-export.xlsx`
      );
    } catch {
      snackbarModule.setSnackbarMessage("Failed to Export");
    }
  }
}
