import { RouteConfig } from "vue-router";
import crewMemberRoutes from "./crewMember";
import dashboardRoutes from "./dashboard";
import { systemAdminRoutes } from "./systemAdmin";
import { vesselRoutes } from "./vessel";
import vesselEnquiryRoutes from "./vesselEnquiry";
import { agencyRoutes } from "./agency";
import { serviceRoutes } from "./service";
import mobileAppRoutes from "./mobileApp";
import { RouteConfigSingleView } from "vue-router/types/router";
import CategorisedRoute from "@/types/CategorisedRoute";
import {companiesRoutes} from "./companiesRoutes";
import companyEnquiryRoutes from "./companyEnquiry";
import landWorkerRoutes from "./landWorker";
import landWorkerDashboardRoutes from "./landWorkerDashboard";
import { agencyRoutesLandWorkers } from "./agencyLandWorkers";

const sidebarRoutes: Array<CategorisedRoute> = [
  {
    path: "/dashboard",
    name: "Dashboard",
    category: "Vessels",
    component: () => import("@/views/dashboard/Index.vue"),
    redirect: { name: "Analytics" },
    meta: { title: "Dashboard", noAuth: false, icon: "mdi-view-dashboard" },
    children: dashboardRoutes,
  },
  {
    path: "/crew-members",
    name: "Crew Members",
    category: "Vessels",
    component: () => import("@/views/Index.vue"),
    redirect: { name: "Crew Member Overview" },
    meta: { title: "Crew Members", noAuth: false, icon: "mdi-account-group" },
    children: crewMemberRoutes
  },
  {
    path: "/vessels",
    name: "Vessels",
    category: "Vessels",
    component: () => import("@/views/vessel/Index.vue"),
    redirect: { name: "Vessel Overview" },
    meta: { title: "Vessels", noAuth: false, icon: "mdi-ferry" },
    children: vesselRoutes
  },
  {
    path: "/vessel-contracts",
    name: "Vessel Contracts",
    category: "Vessels",
    component: () => import("@/views/Index.vue"),
    redirect: { name: "Vessel Enquiry Overview" },
    meta: {
      title: "Vessel Contracts",
      noAuth: false,
      icon: "mdi-book-open"
    },
    children: vesselEnquiryRoutes
  },

  {
    path: "/dashboard-lw",
    name: "Land Worker Dashboard",
    category: "Land Workers",
    component: () => import("@/views/dashboardLandWorkers/Index.vue"),
    redirect: { name: "Land Worker Analytics" },
    meta: { title: "Dashboard", noAuth: false, icon: "mdi-view-dashboard" },
    children: landWorkerDashboardRoutes,
  },
  {
    path: "/land-workers",
    name: "Land Workers",
    category: "Land Workers",
    component: () => import("@/views/Index.vue"),
    redirect: { name: "Land Workers Overview" },
    meta: { title: "Land Workers", noAuth: false, icon: "mdi-account-group" },
    children: landWorkerRoutes
  },
  {
    path: "/companies",
    name: "Companies",
    category: "Land Workers",
    component: () => import("@/views/company/Index.vue"),
    redirect: { name: "Companies Overview" },
    meta: { title: "Companies", noAuth: false, icon: "mdi-factory" },
    children: companiesRoutes
  },
  {
    path: "/company-contracts",
    name: "Company Contracts",
    category: "Land Workers",
    component: () => import("@/views/Index.vue"),
    redirect: { name: "Company Enquiry Overview" },
    meta: {
      title: "Company Contracts",
      noAuth: false,
      icon: "mdi-book-open"
    },
    children: companyEnquiryRoutes
  },
  {
    path: "/agencies",
    name: "Agencies",
    category: "Vessels",
    component: () => import("@/views/agency/Index.vue"),
    redirect: { name: "Agency Overview" },
    meta: {
      title: "Agencies",
      noAuth: false,
      icon: "mdi-domain"
    },
    children: agencyRoutes
  },
  {
    path: "/agencies-land-workers",
    name: "Agencies Land Workers",
    category: "Land Workers",
    component: () => import("@/views/agency/Index.vue"),
    redirect: { name: "Agency Overview Land Workers" },
    meta: {
      title: "Agencies",
      noAuth: false,
      icon: "mdi-domain"
    },
    children: agencyRoutesLandWorkers
  },
  {
    path: "/services",
    name: "Services",
    component: () => import("@/views/service/Index.vue"),
    redirect: { name: "Service Overview" },
    meta: {
      title: "Services",
      noAuth: false,
      icon: "mdi-printer"
    },
    children: serviceRoutes
  },
  {
    path: "/mobile",
    name: "Mobile",
    component: () => import("@/views/mobile-app/Index.vue"),
    // redirect: { name: "Service Overview" },
    meta: {
      title: "Mobile App",
      noAuth: false,
      icon: "mdi-cellphone-text"
    },
    redirect: { name: "Mobile App Overview" },
    children: mobileAppRoutes
  },
  {
    path: "/system-admin",
    name: "System Admin",
    component: () => import("@/views/system-admin/Index.vue"),
    redirect: { name: systemAdminRoutes[0].name },
    meta: { title: "System Admin", noAuth: false, icon: "mdi-cog" },
    children: systemAdminRoutes
  }
];

export default sidebarRoutes;
