import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import sidebarRoutes from "@/router/sidebar";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    meta: { title: "Login", noAuth: true }
  },
  {
    path: "/password-reset/:passwordResetToken",
    name: "Password Reset",
    props: true,
    component: () => import("@/views/PasswordReset.vue"),
    meta: { noAuth: true }
  },
  ...sidebarRoutes,
  {
    path: "/vessel/edit/:vesselId",
    name: "Vessel Edit",
    props: true,
    component: () => import("@/views/vessel/Index.vue"),
    meta: {
      title: "Vessel Edit",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"]
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to: Route, from: Route, next: Function) => {
  document.title = `Vessel Crewing - ${to.meta.title}`;

  const token: string | null = localStorage.getItem("user");
  const requiresAuth = to.meta.requiresAuth;

  if (to.matched.some(record => record.meta.noAuth)) {
    if (token) {
      next({
        name: "Dashboard",
        query: { redirect: to.fullPath }
      });
      return;
    } else {
      next();
    }
  } else {
    if (!token) {
      next({ name: "Login", query: { redirect: to.fullPath } });
    } else {
      if (
        requiresAuth.length &&
        !requiresAuth.includes(JSON.parse(token).role)
      ) {
        next({
          name: "Active Crew Members",
          query: { redirect: to.fullPath }
        });
        return;
      }
      next();
    }
  }
});

export default router;
