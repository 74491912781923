import { RouteConfig } from "vue-router";
import { staffEditRoutes } from "./staffEdit";
import systemAdminOverviewRoutes from "./systemAdminOverview";

export const systemAdminRoutes: Array<RouteConfig> = [
  {
    path: "overview",
    name: "System Admin Overview",
    component: () => import("@/views/system-admin/SystemAdminOverview.vue"),
    meta: {
      title: "User Management",
      noAuth: false,
      requiresAuth: ["USR", "ADM"]
    },
    redirect: { name: "Users" },
    children: systemAdminOverviewRoutes
  },
  {
    path: "edit/:userId",
    name: "Staff Edit Overview",
    component: () => import("@/views/staff/StaffEditIndex.vue"),
    props: true,
    meta: {
      title: "Staff Edit",
      noAuth: false,
      requiresAuth: ["USR", "ADM"],
      icon: "mdi-account-group"
    },
    redirect: { name: "Staff Details Edit" },
    children: staffEditRoutes
  }
];
