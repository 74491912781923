import { RouteConfig } from "vue-router";

export const staffEditRoutes: Array<RouteConfig> = [
  {
    path: "details",
    name: "Staff Details Edit",
    component: () => import("@/views/staff/StaffEdit.vue"),
    props: true,
    meta: {
      title: "Details",
      noAuth: false,
      requiresAuth: ["USR", "ADM"]
    }
  },
  {
    path: "documents",
    name: "Staff Documents",
    component: () => import("@/views/staff/StaffDocuments.vue"),
    props: true,
    meta: {
      title: "Documents",
      noAuth: false,
      requiresAuth: ["USR", "ADM"]
    }
  },
  {
    path: "notes",
    name: "Staff Notes",
    component: () => import("@/views/staff/StaffNotes.vue"),
    props: true,
    meta: {
      title: "Notes",
      noAuth: false,
      requiresAuth: ["USR", "ADM"]
    }
  }
];
