import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import VuetifyConfirm from 'vuetify-confirm';

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#0A4B82",
        actions: "#f4f4f4"
      }
    }
  }
});

Vue.use(VuetifyConfirm, { vuetify: vuetify });

export default vuetify;