import { RouteConfig } from "vue-router";

export const vesselEditRoutes: Array<RouteConfig> = [
  {
    path: "details",
    name: "Vessel Details Edit",
    component: () => import("@/views/vessel/VesselEdit.vue"),
    props: true,
    meta: {
      title: "Details",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"]
    }
  },
  {
    path: "documents",
    name: "Vessel Document",
    component: () => import("@/views/vessel/VesselDocuments.vue"),
    props: true,
    meta: {
      title: "Documents",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"],
      icon: "mdi-document"
    }
  },
  {
    path: "vesselNotes",
    name: "Vessel Notes",
    component: () => import("@/views/vessel/VesselNotes.vue"),
    meta: { title: "Notes", noAuth: false, requiresAuth: ["USR", "ADM"] }
  }
];
