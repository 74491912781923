



















































import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class BaseDataTable extends Vue {
  @Prop({ type: String, default: "" }) private title!: string;
  @Prop({ type: String, default: "Create" }) private btnText!: string;
  @Prop({ type: Boolean, default: false }) private noSearch!: boolean;
  @Prop({ type: Boolean, default: false }) private noCreate!: boolean;
  @Prop({ type: Boolean, default: false }) private exportBtn!: boolean;
  @Prop({ type: Boolean, default: false }) private noCard!: boolean;
  @Prop({ type: String, default: "" }) private sortBy!: string;
  @Prop({ type: Boolean, default: false }) private sortDesc!: boolean;

  private search = "";

  get validSortBy(): string | null {
    return this.sortBy != "" ? this.sortBy : null;
  }

  get validSortDesc(): boolean | null {
    return typeof this.sortDesc === "boolean" ? this.sortDesc : null;
  }
}
