import { RouteConfig } from "vue-router";

const landWorkersEditRoutes: Array<RouteConfig> = [
  {
    path: "details",
    name: "Land Worker Details Edit",
    component: () => import("@/views/land-worker/LandWorkerEdit.vue"),
    props: true,
    meta: {
      title: "Details",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"],
      icon: "mdi-account-group"
    }
  },
  {
    path: "certificates",
    name: "Land Worker Certificate Edit",
    component: () => import("@/views/land-worker/LandWorkerCertificates.vue"),
    props: true,
    meta: {
      title: "Certificates",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"],
      icon: "mdi-account-group"
    }
  },
  {
    path: "documents",
    name: "Land Worker Documents Edit",
    component: () => import("@/views/land-worker/LandWorkerDocuments.vue"),
    props: true,
    meta: {
      title: "Documents",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"],
      icon: "mdi-document"
    }
  },
  {
    path: "notes",
    name: "Land Worker Notes Edit",
    component: () => import("@/views/land-worker/LandWorkerNotes.vue"),
    props: true,
    meta: {
      title: "Notes",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"],
      icon: "mdi-account-group"
    }
  },
  {
    path: "pastContracts",
    name: "Land Worker Past Contracts",
    component: () => import("@/views/land-worker/LandWorkerPastContracts.vue"),
    props: true,
    meta: {
      title: "Past SWFPA Contracts",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"],
      icon: "mdi-account-group"
    }
  }
];

export default landWorkersEditRoutes;
