import { RouteConfig } from "vue-router";
import { staffEditRoutes } from "./staffEdit";

const systemAdminOverviewRoutes: Array<RouteConfig> = [
  {
    path: "users",
    name: "Users",
    component: () => import("@/views/user/UserOverview.vue"),
    meta: {
      title: "User Management",
      noAuth: false,
      requiresAuth: ["USR", "ADM"],
      icon: "mdi-users"
    }
  },
  {
    path: "staff",
    name: "Staff Overview",
    component: () => import("@/views/staff/StaffOverview.vue"),
    meta: {
      title: "Staff Management",
      noAuth: false,
      requiresAuth: ["USR", "ADM"]
    }
  },
  {
    path: "certificate-types",
    name: "Certificate Types",
    component: () =>
      import("@/views/system-admin/CertificateTypeOverview.vue"),
    meta: {
      title: "Certificate Types",
      noAuth: false,
      requiresAuth: ["USR", "ADM"],
      icon: "mdi-certificate"
    }
  },
  {
    path: "certificate-types-land-workers",
    name: "Certificate Types Land Workers",
    component: () =>
      import("@/views/system-admin/CertificateTypeOverviewLandWorkers.vue"),
    meta: {
      title: "Certificate Types",
      noAuth: false,
      requiresAuth: ["USR", "ADM"],
      icon: "mdi-certificate"
    }
  },
  {
    path: "vessel-types",
    name: "Vessel Types",
    component: () => import("@/views/system-admin/VesselTypes.vue"),
    meta: {
      title: "Vessel Types",
      noAuth: false,
      requiresAuth: ["USR", "ADM"],
      icon: "mdi-ferry"
    }
  },
  {
    path: "vessel-job-roles",
    name: "Vessel Worker Roles",
    component: () => import("@/views/system-admin/VesselJobRoles.vue"),
    meta: {
      title: "Job Roles",
      noAuth: false,
      requiresAuth: ["USR", "ADM"],
      icon: "mdi-book-open"
    }
  },
  {
    path: "company-types",
    name: "Company Types",
    component: () => import("@/views/system-admin/CompanyTypes.vue"),
    meta: {
      title: "Company Types",
      noAuth: false,
      requiresAuth: ["USR", "ADM"],
      icon: "mdi-ferry"
    }
  },
  {
    path: "land-worker-job-roles",
    name: "Land Worker Roles",
    component: () => import("@/views/system-admin/LandWorkersJobRoles.vue"),
    meta: {
      title: "Job Roles",
      noAuth: false,
      requiresAuth: ["USR", "ADM"],
      icon: "mdi-book-open"
    }
  }
];

export default systemAdminOverviewRoutes;
