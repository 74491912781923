import { RouteConfig } from "vue-router";

const vesselOverviewRoutes: Array<RouteConfig> = [
  {
    path: "companies",
    name: "Companies",
    component: () => import("@/views/company/Companies.vue"),
    meta: {
      title: "Companies",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"]
    }
  }
];

export default vesselOverviewRoutes;
