import { RouteConfig } from "vue-router";

const crewMemberEditRoutes: Array<RouteConfig> = [
  {
    path: "details",
    name: "Crew Member Details Edit",
    component: () => import("@/views/crew-member/CrewMemberEdit.vue"),
    props: true,
    meta: {
      title: "Details",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"],
      icon: "mdi-account-group"
    }
  },
  {
    path: "certificates",
    name: "Crew Member Certificate Edit",
    component: () => import("@/views/crew-member/CrewMemberCertificates.vue"),
    props: true,
    meta: {
      title: "Certificates",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"],
      icon: "mdi-account-group"
    }
  },
  {
    path: "documents",
    name: "Crew Member Documents Edit",
    component: () => import("@/views/crew-member/CrewMemberDocuments.vue"),
    props: true,
    meta: {
      title: "Documents",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"],
      icon: "mdi-document"
    }
  },
  {
    path: "notes",
    name: "Crew Member Notes Edit",
    component: () => import("@/views/crew-member/CrewMemberNotes.vue"),
    props: true,
    meta: {
      title: "Notes",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"],
      icon: "mdi-account-group"
    }
  },
  {
    path: "pastContracts",
    name: "Crew Member Past Contracts",
    component: () => import("@/views/crew-member/CrewMemberPastContracts.vue"),
    props: true,
    meta: {
      title: "Past SWFPA Contracts",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"],
      icon: "mdi-account-group"
    }
  }
];

export default crewMemberEditRoutes;
