import { render, staticRenderFns } from "./BaseAutocomplete.vue?vue&type=template&id=20636db6&scoped=true&"
import script from "./BaseAutocomplete.vue?vue&type=script&lang=ts&"
export * from "./BaseAutocomplete.vue?vue&type=script&lang=ts&"
import style0 from "./BaseAutocomplete.vue?vue&type=style&index=0&id=20636db6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20636db6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
installComponents(component, {VAutocomplete})
