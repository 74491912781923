import { RouteConfig } from "vue-router";
import vesselEnquiryEditRoutes from "@/router/vesselEnquiryEdit";
import companyEnquiryOverviewRoutes from "./companyEnquiryOverview";
import { companyEditRoutes } from "./companyEdit";
import companyEnquiryEditRoutes from './companiesEnquiryEdit';

const companyEnquiryRoutes: Array<RouteConfig> = [
  {
    path: "overview",
    name: "Company Enquiry Overview",
    component: () => import("@/views/company/enquiry/overview/Index.vue"),
    redirect: { name: "Initial Company Enquiry" },
    meta: {
      title: "Company Enquiries",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"]
    },
    children: companyEnquiryOverviewRoutes
  },
  {
    path: "create",
    name: "Company Enquiry Create",
    component: () => import("@/views/company/enquiry/CompanyEnquiryCreate.vue"),
    meta: {
      title: "Company Enquiry Create",
      noAuth: false,
      requiresAuth: ["USR", "ADM"]
    }
  },
  {
    path: "edit/:vesselEnquiryId",
    name: "Company Enquiry Edit",
    component: () =>
      import("@/views/company/enquiry/CompanyEnquiryEditIndex.vue"),
    redirect: { name: "Company Enquiry Details Edit" },
    props: true,
    meta: {
      title: "Company Enquiry Edit",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"]
    },
    children: companyEnquiryEditRoutes
  }
];

export default companyEnquiryRoutes;
