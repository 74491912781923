import { RouteConfig } from "vue-router";

const landWorkersOverview: Array<RouteConfig> = [
  {
    path: "active",
    name: "Active Land Workers",
    component: () =>
      import("@/views/land-worker/overview/LandWorkerActive.vue"),
    meta: {
      title: "Active",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"]
    }
  },
  {
    path: "available",
    name: "Available Land Workers",
    component: () =>
      import("@/views/land-worker/overview/LandWorkerAvailable.vue"),
    meta: {
      title: "Available",
      noAuth: false,
      requiresAuth: ["USR", "ADM"]
    }
  },
  {
    path: "certificates/expiring",
    name: "Crew Member Expiring Certificates",
    component: () =>
      import(
        "@/components/crew-member/CrewMemberExpiringCertificatesTable.vue"
      ),
    props: true,
    meta: {
      title: "Expiring Certificates",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"]
    }
  },
  {
    path: "passports/expiring",
    name: "Crew Member Expiring Passports",
    component: () =>
      import("@/components/crew-member/CrewMemberExpiringPassportTable.vue"),
    props: true,
    meta: {
      title: "Expiring Passports",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"]
    }
  }
];

export default landWorkersOverview;
