import { RouteConfig } from "vue-router";
import mobileAppAdminRoutes from "./MobileAppAdmin";

export const mobileAppRoutes: Array<RouteConfig> = [
  {
    path: "overview",
    name: "Mobile App Overview",
    component: () => import("@/views/mobile-app/Overview.vue"),
    meta: {
      title: "Mobile App",
      noAuth: false,
      requiresAuth: ["USR", "ADM"]
    },
    redirect: { name: "Messages" },
    children: mobileAppAdminRoutes
  },
];


export default mobileAppRoutes;
