import { RouteConfig } from "vue-router";

const crewMemberOverviewRoutes: Array<RouteConfig> = [
  {
    path: "active",
    name: "Active Crew Members",
    component: () =>
      import("@/views/crew-member/overview/CrewMemberActive.vue"),
    meta: {
      title: "Active",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"]
    }
  },
  {
    path: "available",
    name: "Available Crew Members",
    component: () =>
      import("@/views/crew-member/overview/CrewMemberAvailable.vue"),
    meta: {
      title: "Available",
      noAuth: false,
      requiresAuth: ["USR", "ADM"]
    }
  },
  {
    path: "activeSkillWorkers",
    name: "Active Skill Workers",
    component: () =>
      import("@/views/crew-member/overview/CrewMemberActiveSkillWorkers.vue"),
    meta: {
      title: "Active Skill Workers",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"]
    }
  },
  {
    path: "availableSkillWorkers",
    name: "Available Skill Workers",
    component: () =>
      import(
        "@/views/crew-member/overview/CrewMemberAvailableSkillWorkers.vue"
      ),
    meta: {
      title: "Available Skill Workers",
      noAuth: false,
      requiresAuth: ["USR", "ADM"]
    }
  },
  {
    path: "certificates/expiring",
    name: "Crew Member Expiring Certificates",
    component: () =>
      import(
        "@/components/crew-member/CrewMemberExpiringCertificatesTable.vue"
      ),
    props: true,
    meta: {
      title: "Expiring Certificates",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"]
    }
  },
  {
    path: "passports/expiring",
    name: "Crew Member Expiring Passports",
    component: () =>
      import("@/components/crew-member/CrewMemberExpiringPassportTable.vue"),
    props: true,
    meta: {
      title: "Expiring Passports",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"]
    }
  }
];

export default crewMemberOverviewRoutes;
