

























import { Vue, Component, Prop } from 'vue-property-decorator';
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import { ContractType, JobRoleModel } from "@/api/generated";
import getContractType from '../../../utility/getContractType';
import Discriminator from '../../../types/Discriminator';

const snackbarModule = getModule(SnackbarModule);

@Component
export default class JobRoleAutocomplete extends Vue {
  @Prop(String) private topLabel?: string;
  @Prop({default: Discriminator.VESSELS}) discriminator!: Discriminator;

  private loading = false;
  private jobRoles: Array<JobRoleModel> = [];

  private async created() {
    try {
      this.loading = true;
      const response = await Api.JobRoleService.apiJobroleDiscriminatorGet(getContractType(this.discriminator));
      this.jobRoles = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to load job roles");
    } finally {
      this.loading = false; 
    }
  }
}
