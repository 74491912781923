import { RouteConfig } from "vue-router";
import { staffEditRoutes } from "./staffEdit";

const mobileAppAdminRoutes: Array<RouteConfig> = [
  {
    path: "message-groups",
    name: "Message Groups",
    component: () => import("@/views/mobile-app/MessageGroupOverview.vue"),
    meta: {
      title: "Message Groups",
      noAuth: false,
      requiresAuth: ["USR", "ADM"],
      icon: "mdi-users",
    },
  },
  {
    path: "vacancies",
    name: "Vacancies",
    component: () => import("@/views/mobile-app/Vacancies.vue"),
    meta: {
      title: "Vacancies",
      noAuth: false,
      requiresAuth: ["USR", "ADM"],
      icon: "mdi-users",
    },
  },
  {
    path: "important-info",
    name: "Important Info",
    component: () => import("@/views/mobile-app/ImportantInfo.vue"),
    meta: {
      title: "Important Info",
      noAuth: false,
      requiresAuth: ["USR", "ADM"],
      icon: "mdi-users",
    },
  },
  {
    path: "messages",
    name: "Messages",
    component: () => import("@/views/mobile-app/Messages.vue"),
    meta: {
      title: "Messages",
      noAuth: false,
      requiresAuth: ["USR", "ADM"],
      icon: "mdi-users",
    },
  },
  {
    path: "waypoints",
    name: "Waypoints",
    component: () => import("@/views/mobile-app/Waypoints.vue"),
    meta: {
      title: "Waypoints",
      noAuth: false,
      requiresAuth: ["USR", "ADM"],
      icon: "mdi-users",
    },
  },
  {
    path: "international-dates",
    name: "International Dates",
    component: () => import("@/views/mobile-app/InternationalDates.vue"),
    meta: {
      title: "International Dates",
      noAuth: false,
      requiresAuth: ["USR", "ADM"],
      icon: "mdi-users",
    },
  },
  {
    path: "app-users",
    name: "App Users",
    component: () => import("@/views/user/UserOverview.vue"),
    meta: {
      title: "App Users",
      noAuth: false,
      requiresAuth: ["USR", "ADM"],
      icon: "mdi-users",
    },
  },
];

export default mobileAppAdminRoutes;
