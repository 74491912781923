import { RouteConfig } from "vue-router";

const vesselOverviewRoutes: Array<RouteConfig> = [
  {
    path: "vessel",
    name: "Vessels",
    component: () => import("@/views/vessel/AvailableVessels.vue"),
    meta: {
      title: "Vessels",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"]
    }
  },
  {
    path: "sponsoredVessel",
    name: "SponsoredVessels",
    component: () => import("@/views/vessel/SponsoredVessels.vue"),
    meta: {
      title: "Sponsored Vessels",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"]
    }
  },
  {
    path: "doNotUse",
    name: "Do Not Use Vessels",
    component: () => import("@/views/vessel/VesselDoNotUse.vue"),
    meta: {
      title: "Do not Use",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"]
    }
  }
];

export default vesselOverviewRoutes;
