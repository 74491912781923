import { apiClient } from "@/services/apiService";
import { AxiosResponse } from "axios";
import { StringIterator } from "lodash";
import mime from "mime-types";

export default {
  async exportAllVessels(search: string) {
    const response = await apiClient.get(`api/vessel/export/${search}`, {
      responseType: "blob"
    });
    const file = {
      data: response.data,
      extension: mime.extension(response.data.type),
      name: response.data.name
    };

    return file;
  },

  async exportAllVesselsWithContracts(search: string) {
    const response = await apiClient.get(
      `api/vessel/export-with-contracts/${search}`,
      {
        responseType: "blob"
      }
    );
    const file = {
      data: response.data,
      extension: mime.extension(response.data.type),
      name: response.data.name
    };

    return file;
  },

  async exportAllVesselsWithoutContracts(search: string) {
    const response = await apiClient.get(
      `api/vessel/export-without-contracts/${search}`,
      {
        responseType: "blob"
      }
    );
    const file = {
      data: response.data,
      extension: mime.extension(response.data.type),
      name: response.data.name
    };

    return file;
  },

  async exportAllActiveCrewMembers() {
    const response = await apiClient.get(`api/crewMember/export-active`, {
      responseType: "blob"
    });
    const file = {
      data: response.data,
      extension: mime.extension(response.data.type),
      name: response.data.name
    };

    return file;
  },

  async exportAllAvailableCrewMembers() {
    const response = await apiClient.get(`api/crewMember/export-available`, {
      responseType: "blob"
    });
    const file = {
      data: response.data,
      extension: mime.extension(response.data.type),
      name: response.data.name
    };

    return file;
  },

  async exportAllAvailableCrewMembersByNationality(countryId: string) {
    const response = await apiClient.get(
      `api/crewMember/export-available-nationality/${countryId}`,
      {
        responseType: "blob"
      }
    );
    const file = {
      data: response.data,
      extension: mime.extension(response.data.type),
      name: response.data.name
    };

    return file;
  },

  async exportAllActiveCrewMembersByNationality(countryId: string) {
    const response = await apiClient.get(
      `api/crewMember/export-active-nationality/${countryId}`,
      {
        responseType: "blob"
      }
    );
    const file = {
      data: response.data,
      extension: mime.extension(response.data.type),
      name: response.data.name
    };

    return file;
  },

  async exportAllActiveCrewMembersByJobRole(jobRoleId: string) {
    const response = await apiClient.get(
      `api/crewMember/export-active-jobrole/${jobRoleId}`,
      {
        responseType: "blob"
      }
    );
    const file = {
      data: response.data,
      extension: mime.extension(response.data.type),
      name: response.data.name
    };

    return file;
  },

  async exportAllAvailableCrewMembersByJobRole(jobRoleId: string) {
    const response = await apiClient.get(
      `api/crewMember/export-available-jobrole/${jobRoleId}`,
      {
        responseType: "blob"
      }
    );
    const file = {
      data: response.data,
      extension: mime.extension(response.data.type),
      name: response.data.name
    };

    return file;
  },

  async exportAllSkilledWorkers() {
    const response = await apiClient.get(
      `api/crewMember/export-skilled-workers`,
      {
        responseType: "blob"
      }
    );
    const file = {
      data: response.data,
      extension: mime.extension(response.data.type),
      name: response.data.name
    };

    return file;
  }
};
