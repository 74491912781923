import { ContractType } from '@/api/generated';
import Discriminator from '../types/Discriminator';
export default function getContractType(type: Discriminator) {
  if (type == Discriminator.VESSELS) {
    return ContractType.NUMBER_0
  } else if (type == Discriminator.LAND_WORKERS) {
    return ContractType.NUMBER_1
  } else {
    return ContractType.NUMBER_2
  }
}