




















import Vue from 'vue';
import { RouteConfigSingleView } from 'vue-router/types/router';

export default Vue.extend({
  props: ["routes"],
  computed: {
    navRoutes() : RouteConfigSingleView[] {
      return this.routes;
    }
  }
});
