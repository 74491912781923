import { apiClient, fileClient } from "@/services/apiService";
import {
  AgencyApi,
  AgencyDocumentApi,
  AuthApi,
  CertificateTypeApi,
  CertificateTypeVesselEnquiryApi,
  ConstantApi,
  CountryApi,
  CrewMemberApi,
  CrewMemberCertificateApi,
  CrewMemberDocumentApi,
  CrewMemberNoteApi,
  CrewMemberVesselEnquiryApi,
  DocumentApi,
  ServiceApi,
  ServiceDocumentApi,
  ServiceNoteApi,
  StatementApi,
  JobRoleApi,
  ReportApi,
  UserApi,
  VesselApi,
  VesselEnquiryApi,
  VesselEnquiryDocumentApi,
  VesselTypeApi,
  VesselDocumentApi,
  VesselNoteApi,
  UserDocumentApi,
  UserNoteApi
} from "./generated";

export default {
  AgencyService: new AgencyApi(undefined, "", apiClient),
  AgencyDocumentService: new AgencyDocumentApi(undefined, "", apiClient),
  AuthService: new AuthApi(undefined, "", apiClient),
  CertificateTypeService: new CertificateTypeApi(undefined, "", apiClient),
  CertificateTypeVesselEnquiryService: new CertificateTypeVesselEnquiryApi(
    undefined,
    "",
    apiClient
  ),
  ConstantApi: new ConstantApi(undefined, "", apiClient),
  CountryService: new CountryApi(undefined, "", apiClient),
  CrewMemberDocumentService: new CrewMemberDocumentApi(
    undefined,
    "",
    apiClient
  ),
  CrewMemberCertificateService: new CrewMemberCertificateApi(
    undefined,
    "",
    apiClient
  ),
  CrewMemberService: new CrewMemberApi(undefined, "", apiClient),
  CrewMemberNoteService: new CrewMemberNoteApi(undefined, "", apiClient),
  CrewMemberVesselEnquiryService: new CrewMemberVesselEnquiryApi(
    undefined,
    "",
    apiClient
  ),
  DocumentService: new DocumentApi(undefined, "", fileClient),
  StatementService: new StatementApi(undefined, "", apiClient),
  ReportService: new ReportApi(undefined, "", fileClient),
  ServiceService: new ServiceApi(undefined, "", apiClient),
  ServiceDocumentService: new ServiceDocumentApi(undefined, "", apiClient),
  ServiceNoteService: new ServiceNoteApi(undefined, "", apiClient),
  JobRoleService: new JobRoleApi(undefined, "", apiClient),
  UserService: new UserApi(undefined, "", apiClient),
  VesselService: new VesselApi(undefined, "", apiClient),
  VesselTypeService: new VesselTypeApi(undefined, "", apiClient),
  VesselEnquiryService: new VesselEnquiryApi(undefined, "", apiClient),
  VesselEnquiryDocumentService: new VesselEnquiryDocumentApi(
    undefined,
    "",
    apiClient
  ),
  VesselDocumentService: new VesselDocumentApi(undefined, "", apiClient),
  VesselNoteService: new VesselNoteApi(undefined, "", apiClient),
  UserDocumentService: new UserDocumentApi(undefined, "", apiClient),
  UserNoteService: new UserNoteApi(undefined, "", apiClient)
};
