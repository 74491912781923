import { RouteConfig } from "vue-router";
import crewMemberEditRoutes from "./crewMemberEdit";
import crewMemberOverviewRoutes from "./crewMemberOverview";
import crewMemberAgencyAdminEditRoutes from "./crewMemberAgencyAdminEdit";

const crewMemberRoutes: Array<RouteConfig> = [
  {
    path: "overview",
    name: "Crew Member Overview",
    component: () => import("@/views/crew-member/CrewMemberOverview.vue"),
    meta: {
      title: "Crew Members",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"]
    },
    redirect: { name: "Active Crew Members" },
    children: crewMemberOverviewRoutes
  },
  {
    path: "create",
    name: "Crew Member Create",
    component: () => import("@/views/crew-member/CrewMemberCreate.vue"),
    meta: {
      title: "Crew Member Create",
      noAuth: false,
      requiresAuth: ["USR", "ADM"]
    }
  },
  {
    path: "edit/:crewMemberId",
    name: "Crew Member Edit",
    component: () => import("@/views/crew-member/CrewMemberEditIndex.vue"),
    redirect: { name: "Crew Member Details Edit" },
    props: true,
    meta: {
      title: "Crew Member Edit",
      noAuth: false,
      requiresAuth: ["USR", "ADM"],
      icon: "mdi-account-group"
    },
    children: crewMemberEditRoutes
  },
  {
    path: "edit/:crewMemberId",
    name: "Crew Member Edit Agency",
    component: () =>
      import("@/views/crew-member/CrewMemberAvailableAgencyAdminEditIndex.vue"),
    redirect: { name: "Crew Member Certificate Agency Admin Edit" },
    props: true,
    meta: {
      title: "Crew Member Edit",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"],
      icon: "mdi-account-group"
    },
    children: crewMemberAgencyAdminEditRoutes
  }
];

export default crewMemberRoutes;
