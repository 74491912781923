








































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import AuthenticatedPasswordReset from "@/components/dialogs/AuthenticatedPasswordReset.vue";
import sidebarRoutes from "@/router/sidebar";
import sidebarAgencyAdminRoutes from "@/router/sidebarAgencyAdmin";
import UserModule from "@/store/userModule";
import CategorisedRoute from '../../types/CategorisedRoute';
import { groupBy } from "lodash";
import RouteList from "./RouteList.vue";

const userModule = getModule(UserModule);

@Component({ components: { AuthenticatedPasswordReset, RouteList } })
export default class TheSidebar extends Vue {
  @Prop(Boolean) readonly value: boolean | null = null;
  private resetDialog = false;
  public expandedPanels: number[] = [];

  public get routes() : CategorisedRoute[] {
    if (userModule.role == "AGM") {
      return sidebarAgencyAdminRoutes;
    } else {
      return sidebarRoutes;
    }
  }

  public get uncategorizedRoutes() {
    return this.routes.filter(route => !route.category);
  }

  public get categorizedRoutes() {
    return groupBy(
      this.routes.filter((route: CategorisedRoute) => route.category),
      (route: CategorisedRoute) => route.category
    )
  }

  @Watch("expandedPanels")
  panelsChanged() {
    localStorage.setItem("expanded-indices", JSON.stringify(this.expandedPanels));
  }
  
  mounted() {
    const expandedIndices = localStorage.getItem("expanded-indices");

    if (expandedIndices) this.expandedPanels = JSON.parse(expandedIndices) as number[];
  }
}
