import Vue from "vue";
import moment from "moment";
import momentUtility from "@/utility/momentUtility";
import { StatementChargeRecurrenceType } from "@/api/generated";

export default {
  register() {
    Vue.filter("dateAge", (value: string) => {
      if (!value) {
        return value;
      }

      if (!moment(value).isValid()) {
        return value;
      }

      return moment().diff(value, "years");
    });

    Vue.filter("yesNo", (value: boolean) => {
      return value ? "Yes" : "No";
    });

    Vue.filter("passFailNullable", (value: boolean | undefined) => {
      if (value === null) {
        return "-";
      }

      return value ? "mdi-check-circle" : "mdi-close-circle";
    });

    Vue.filter("formatDate", (value: string) => {
      if (!value) {
        return "N/A";
      }

      return momentUtility.formatDate(value);
    });

    Vue.filter("formatLongDateTime", (value: string) => {
      if (!value) {
        return "N/A";
      }

      return momentUtility.longDateTime(value);
    });

    Vue.filter("currencyGbp", (value: number) => {
      const formatter = new Intl.NumberFormat("en-Gb", {
        style: "currency",
        currency: "GBP",
        minimumFractionDigits: 2
      });
      return formatter.format(value);
    });

    Vue.filter("recurrenceTypeFilter", (type: StatementChargeRecurrenceType) => {
      if (type === StatementChargeRecurrenceType.NUMBER_0) {
        return "First Invoice Only";
      } else if (type === StatementChargeRecurrenceType.NUMBER_1) {
        return "Last Invoice Only";
      } else {
        return "Every Invoice";
      }
    });
  }
};
