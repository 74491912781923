import { RouteConfig } from "vue-router";
import vesselEnquiryEditRoutes from "@/router/vesselEnquiryEdit";
import vesselEnquiryOverviewRoutes from "./vesselEnquiryOverview";

const vesselEnquiryRoutes: Array<RouteConfig> = [
  {
    path: "overview",
    name: "Vessel Enquiry Overview",
    component: () => import("@/views/vessel/enquiry/overview/Index.vue"),
    redirect: { name: "Initial Vessel Enquiry" },
    meta: {
      title: "Vessel Enquiries",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"]
    },
    children: vesselEnquiryOverviewRoutes
  },
  {
    path: "create",
    name: "Vessel Enquiry Create",
    component: () => import("@/views/vessel/enquiry/VesselEnquiryCreate.vue"),
    meta: {
      title: "Vessel Enquiry Create",
      noAuth: false,
      requiresAuth: ["USR", "ADM"]
    }
  },
  {
    path: "edit/:vesselEnquiryId",
    name: "Vessel Enquiry Edit",
    component: () =>
      import("@/views/vessel/enquiry/VesselEnquiryEditIndex.vue"),
    redirect: { name: "Vessel Enquiry Details Edit" },
    props: true,
    meta: {
      title: "Vessel Enquiry Edit",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"]
    },
    children: vesselEnquiryEditRoutes
  }
];

export default vesselEnquiryRoutes;
