
































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import excelExportService from "@/services/excelExportService";
import fileDownload from "js-file-download";
import SnackbarModule from "@/store/snackbarModule";
import NationalityAutocomplete from "@/components/common/country/NationalityAutocomplete.vue";
import JobRoleAutocomplete from "@/components/common/job-role/JobRoleAutocomplete.vue";
import UserModule from "@/store/userModule";

const userModule = getModule(UserModule);
const snackbarModule = getModule(SnackbarModule);

@Component({
  components: {
    NationalityAutocomplete,
    JobRoleAutocomplete
  }
})
export default class BaseDataTable extends Vue {
  @Prop({ type: String, default: "" }) private title!: string;
  @Prop({ type: String, default: "Create" }) private btnText!: string;
  @Prop({ type: Boolean, default: false }) private noSearch!: boolean;
  @Prop({ type: Boolean, default: false }) private noCreate!: boolean;
  @Prop({ type: Boolean, default: false }) private exportBtn!: boolean;
  @Prop({ type: Boolean, default: false }) private noCard!: boolean;
  @Prop(Boolean) isCompaniesTable?: boolean;

  private exportActiveNationalityDialog = false;
  private exportActiveJobRoleDialog = false;
  private exportAvailableNationalityDialog = false;
  private exportAvailableJobRoleDialog = false;
  private search = "";
  private countryId = "";
  private jobRoleId = "";

  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }

  private async exportAllActiveCrew() {
    try {
      const file = await excelExportService.exportAllActiveCrewMembers();
      fileDownload(
        new Blob([file.data], {
          type: file.extension ? file.extension : "undefined"
        }),
        `Crew In Country-export.xlsx`
      );
    } catch {
      snackbarModule.setSnackbarMessage("Failed to Export");
    }
  }

  private async exportActiveNationality() {
    try {
      const file = await excelExportService.exportAllActiveCrewMembersByNationality(
        this.countryId
      );
      fileDownload(
        new Blob([file.data], {
          type: file.extension ? file.extension : "undefined"
        }),
        `Crew In Country By Nationality-export.xlsx`
      );
    } catch {
      snackbarModule.setSnackbarMessage("Failed to Export");
    }
  }

  private async exportActiveJobRole() {
    try {
      const file = await excelExportService.exportAllActiveCrewMembersByJobRole(
        this.jobRoleId
      );
      fileDownload(
        new Blob([file.data], {
          type: file.extension ? file.extension : "undefined"
        }),
        `Crew In Country By Job Role-export.xlsx`
      );
    } catch {
      snackbarModule.setSnackbarMessage("Failed to Export");
    }
  }

  private async exportAllAvailableCrew() {
    try {
      const file = await excelExportService.exportAllAvailableCrewMembers();
      fileDownload(
        new Blob([file.data], {
          type: file.extension ? file.extension : "undefined"
        }),
        `Crew Out Country-export.xlsx`
      );
    } catch {
      snackbarModule.setSnackbarMessage("Failed to Export");
    } finally {
      this.exportActiveNationalityDialog = false;
    }
  }

  private async exportAvailableNationality() {
    try {
      const file = await excelExportService.exportAllAvailableCrewMembersByNationality(
        this.countryId
      );
      fileDownload(
        new Blob([file.data], {
          type: file.extension ? file.extension : "undefined"
        }),
        `Crew Out Country By Nationality-export.xlsx`
      );
    } catch {
      snackbarModule.setSnackbarMessage("Failed to Export");
    } finally {
      this.exportAvailableNationalityDialog = false;
    }
  }

  private async exportAvailableJobRole() {
    try {
      const file = await excelExportService.exportAllAvailableCrewMembersByJobRole(
        this.jobRoleId
      );
      fileDownload(
        new Blob([file.data], {
          type: file.extension ? file.extension : "undefined"
        }),
        `Crew Out Country By Job Role-export.xlsx`
      );
    } catch {
      snackbarModule.setSnackbarMessage("Failed to Export");
    } finally {
      this.exportAvailableJobRoleDialog = false;
    }
  }

  private async exportSkilledWorkers() {
    try {
      const file = await excelExportService.exportAllSkilledWorkers();
      fileDownload(
        new Blob([file.data], {
          type: file.extension ? file.extension : "undefined"
        }),
        `Skilled Workers-export.xlsx`
      );
    } catch {
      snackbarModule.setSnackbarMessage("Failed to Export");
    } finally {
      this.exportAvailableJobRoleDialog = false;
    }
  }
}
