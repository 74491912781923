






































import { Vue, Component, VModel, Prop } from "vue-property-decorator";
import momentUtility from "@/utility/momentUtility";

@Component
export default class BaseDatePicker extends Vue {
  @VModel() private date!: string;
  @Prop({ type: Boolean, default: false }) private year!: boolean;

  private menu = false;

  private get dateText() {
    if (this.year) {
      return momentUtility.formatYear(this.date);
    }

    return momentUtility.formatDate(this.date);
  }
}
