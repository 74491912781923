


















import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class BaseAutocomplete extends Vue {
  @Prop(String) readonly topLabel!: string;
}
