


















import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class BaseSelect extends Vue {
  @Prop(String) readonly topLabel!: string;
}
