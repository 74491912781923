import { RouteConfig } from "vue-router";

const landWorkerDashboardRoutes: Array<RouteConfig> = [
  {
    path: "analytics",
    name: "Land Worker Analytics",
    component: () => import("@/views/dashboardLandWorkers/Analytics.vue"),
    props: true,
    meta: {
      title: "Analytics",
      noAuth: false,
      requiresAuth: ["USR", "ADM"]
    }
  }
];

export default landWorkerDashboardRoutes;
