import { RouteConfig } from "vue-router";
import { agencyEditRoutes } from "./agencyEditRoutes";

export const agencyRoutesLandWorkers: Array<RouteConfig> = [
  {
    path: "overview",
    name: "Agency Overview Land Workers",
    component: () => import("@/views/agency/AgencyOverviewLandWorkers.vue"),
    meta: {
      title: "Agencies",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"]
    }
  }
  // {
  //   path: "edit/:agencyId",
  //   name: "Agency Edit",
  //   component: () => import("@/views/agency/AgencyEditOverview.vue"),
  //   redirect: { name: "Agency Edit Details" },
  //   props: true,
  //   meta: {
  //     title: "Agency Edit",
  //     noAuth: false,
  //     requiresAuth: ["USR", "ADM"],
  //     icon: "mdi-account-group"
  //   },
  //   children: agencyEditRoutes
  // }
];
