











import Vue from "vue";
import Component from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import UserModule from "@/store/userModule";
import TheNavbar from "@/components/common/TheNavbar.vue";
import TheSidebar from "@/components/common/TheSidebar.vue";
import TheSnackbar from "@/components/common/TheSnackbar.vue";

const userModule = getModule(UserModule);

@Component({ components: { TheNavbar, TheSidebar, TheSnackbar } })
export default class App extends Vue {
  private drawer: boolean | null = null;

  get getAuthorized(): boolean {
    return userModule.loggedIn;
  }
}
