import { RouteConfig } from "vue-router";

const vesselEnquiryOverviewRoutes: Array<RouteConfig> = [
  {
    path: "initial",
    name: "Initial Vessel Enquiry",
    component: () =>
      import(
        "@/views/vessel/enquiry/overview/VesselEnquiryInitialOverview.vue"
      ),
    meta: {
      title: "Vessel Enquiries",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"]
    }
  },
  {
    path: "open",
    name: "Vessel Enquiry Open",
    component: () =>
      import("@/views/vessel/enquiry/overview/VesselEnquiryOpenOverview.vue"),
    meta: {
      title: "Active Contracts",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"]
    }
  },
  {
    path: "expiring",
    name: "Expiring Contracts",
    component: () =>
      import(
        "@/views/vessel/enquiry/overview/VesselEnquiryExpiringOverview.vue"
      ),
    meta: {
      title: "Expiring Contracts",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"]
    }
  },
  {
    path: "expired",
    name: "Expired Contracts",
    component: () =>
      import(
        "@/views/vessel/enquiry/overview/VesselEnquiryExpiredOverview.vue"
      ),
    meta: {
      title: "Expired Contracts",
      noAuth: false,
      requiresAuth: ["USR", "ADM"]
    }
  },
  {
    path: "closed",
    name: "Vessel Enquiry Closed",
    component: () =>
      import("@/views/vessel/enquiry/overview/VesselEnquiryClosedOverview.vue"),
    meta: {
      title: "Closed Contracts",
      noAuth: false,
      requiresAuth: ["USR", "ADM"]
    }
  }
];

export default vesselEnquiryOverviewRoutes;
