import { RouteConfig } from "vue-router";

const crewMemberAgencyAdminEditRoutes: Array<RouteConfig> = [
  {
    path: "certificates",
    name: "Crew Member Certificate Agency Admin Edit",
    component: () => import("@/views/crew-member/CrewMemberCertificates.vue"),
    props: true,
    meta: {
      title: "Certificates",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"],
      icon: "mdi-account-group"
    }
  },
  {
    path: "pastContracts",
    name: "Crew Member Agency Admin Past Contracts",
    component: () => import("@/views/crew-member/CrewMemberPastContracts.vue"),
    props: true,
    meta: {
      title: "Past SWFPA Contracts",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"],
      icon: "mdi-account-group"
    }
  }
];

export default crewMemberAgencyAdminEditRoutes;
