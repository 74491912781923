import { RouteConfig } from "vue-router";
import { vesselEditRoutes } from "./vesselEdit";
import vesselOverviewRoutes from "./vesselOverview";

export const vesselRoutes: Array<RouteConfig> = [
  {
    path: "overview",
    name: "Vessel Overview",
    component: () => import("@/views/vessel/VesselOverview.vue"),
    meta: {
      title: "Vessels",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"]
    },
    redirect: { name: "Vessels" },
    children: vesselOverviewRoutes
  },
  {
    path: "edit/:vesselId",
    name: "Vessel Edit",
    component: () => import("@/views/vessel/EditIndex.vue"),
    redirect: { name: "Vessel Details Edit" },
    props: true,
    meta: {
      title: "Vessel Edit",
      noAuth: false,
      requiresAuth: ["USR", "ADM", "AGM"],
      icon: "mdi-account-group"
    },
    children: vesselEditRoutes
  }
];
