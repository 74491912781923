import { RouteConfig } from "vue-router";
import { serviceEditRoutes } from "./serviceEditRoutes";

export const serviceRoutes: Array<RouteConfig> = [
  {
    path: "overview",
    name: "Service Overview",
    component: () => import("@/views/service/ServiceOverview.vue"),
    meta: {
      title: "Services",
      noAuth: false,
      requiresAuth: ["USR", "ADM"]
    }
  },
  {
    path: "edit/:serviceId",
    name: "Service Edit",
    component: () => import("@/views/service/ServiceEditOverview.vue"),
    redirect: { name: "Service Edit Details" },
    props: true,
    meta: {
      title: "Service Edit",
      noAuth: false,
      requiresAuth: ["USR", "ADM"],
      icon: "mdi-account-group"
    },
    children: serviceEditRoutes
  }
];
